import { PlusIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CheckoutPlusAction,
  CheckoutPlusElementKind,
  CheckoutPlusInsertionStrategy,
  isTruthy,
  WIDGET_DEFAULTS,
} from 'corso-types';

import { FormEventHandler, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import {
  SwitchInput,
  TextAreaInput,
  TextInput,
  UrlInput,
} from '~/components/field';
import FloatingSave from '~/components/FloatingSave';
import Page from '~/components/Page';
import SpinnerIcon from '~/components/SpinnerIcon';
import { Action } from '~/components/ui/Action';
import { MultiSelect } from '~/components/ui/MultiSelect';
import { Badge } from '~/components/ui/primitives/Badge';
import { Label } from '~/components/ui/primitives/Label';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
  useConfigureGsp,
} from '~/hooks/useConfigSettings';
import { useFactValues } from '~/hooks/useFactValues';
import { useStoreId } from '~/hooks/useStoreId';
import { ShippingPlusWidgetPreview } from '~/pages/settings/ShippingPlusSettings/ShippingPlusWidgetPreview';
import {
  shipProtectSettingsFormSchema,
  ShipProtectSettingsFormValues,
} from '~/types';

const checkoutPlusSettingsFormId = 'checkout-plus-settings';

const insertionStrategyText = {
  [CheckoutPlusInsertionStrategy.insertAdjacentAfterBegin]:
    'Insert inside the element, at the top',
  [CheckoutPlusInsertionStrategy.insertAdjacentBeforeEnd]:
    'Insert inside the element, at the bottom',
  [CheckoutPlusInsertionStrategy.insertAdjacentBeforeBegin]:
    'Insert before the element',
  [CheckoutPlusInsertionStrategy.insertAdjacentAfterEnd]:
    'Insert after the element',
} satisfies Record<CheckoutPlusInsertionStrategy, string>;

const insertStrategyOptions = Object.entries(insertionStrategyText).map(
  ([value, label]) => ({ value, label }),
);

const checkoutActionText = {
  [CheckoutPlusAction.addVariantToCart]: 'Add product to cart',
  [CheckoutPlusAction.setShippingPlusOptOut]: 'Opt out of Delivery Guarantee',
  [CheckoutPlusAction.setShippingPlusReorderRate]:
    'Default shipping to a Shipping Plus rate',
} satisfies Record<CheckoutPlusAction, string>;

const checkoutActionOptions = Object.entries(checkoutActionText).map(
  ([value, label]) => ({ value, label }),
);

const checkoutPlusElementKindText = {
  [CheckoutPlusElementKind.altCheckoutButton]: 'Opt-Out Checkout Button',
  [CheckoutPlusElementKind.checkoutInfo]: 'Checkout Plus Info',
} satisfies Record<CheckoutPlusElementKind, string>;

const checkoutPlusElementKindOptions = Object.entries(
  checkoutPlusElementKindText,
).map(([value, label]) => ({ value, label }));

function CheckoutPlusElements() {
  const methods = useFormContext<ShipProtectSettingsFormValues>();

  const {
    fields: elementFields,
    append: appendElement,
    remove: removeElement,
  } = useFieldArray({
    control: methods.control,
    name: 'widgetConfig.checkoutPlus.elements',
  });

  const elementsState = methods.getFieldState(
    'widgetConfig.checkoutPlus.elements',
  );

  return (
    <Card>
      <div className="flex flex-row items-center justify-between">
        <Card.Heading>Checkout Plus Elements</Card.Heading>
        <Action
          icon={PlusIcon}
          onClick={() =>
            appendElement(WIDGET_DEFAULTS.checkoutPlus.elements[0])
          }
          accessibilityLabel="Add Checkout Plus Element"
          variant="ghost"
        />
      </div>
      {elementsState.error?.message && (
        <Alert
          title="Error"
          message={elementsState.error.message}
          variant="danger"
        />
      )}

      {elementFields.map((field, index) => {
        // You can run any custom logic here before rendering the JSX
        // For example: console.log('Rendering field', index);
        const selectedKind = methods.watch(
          `widgetConfig.checkoutPlus.elements.${index}.kind`,
        );

        return (
          <div
            key={field.id}
            className="m-2 flex flex-col gap-3 rounded-2xl border border-gray-200 bg-white p-6 shadow-sm"
          >
            <Controller
              name={`widgetConfig.checkoutPlus.elements.${index}.kind`}
              control={methods.control}
              render={({ field: { onChange, value }, fieldState }) => (
                <SimpleSelect
                  label="Type"
                  details="The type of Checkout Plus element to display."
                  options={checkoutPlusElementKindOptions}
                  value={value} // be warned, react-hook-form is lying about the value type, as there's no default value
                  onChange={onChange}
                  error={fieldState.error?.message}
                  required
                />
              )}
            />

            <Controller
              control={methods.control}
              name={`widgetConfig.checkoutPlus.elements.${index}.selectors`}
              render={({ field: f, fieldState }) => (
                <MultiSelect
                  creatable
                  label="Selectors"
                  options={[]}
                  details="The CSS selectors for the Checkout Plus element."
                  placeholder="Add Selector"
                  value={
                    f.value?.map((v) => ({
                      value: v.toString(),
                      label: v,
                    })) ?? []
                  }
                  onChange={(o) => f.onChange(o.map((v) => v.value))}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name={`widgetConfig.checkoutPlus.elements.${index}.insertionStrategy`}
              control={methods.control}
              render={({ field: { onChange, value }, fieldState }) => (
                <SimpleSelect
                  label="Insert Strategy"
                  details="Where to insert the Checkout Plus element, relative to the selectors."
                  options={insertStrategyOptions}
                  value={value} // be warned, react-hook-form is lying about the value type, as there's no default value
                  onChange={onChange}
                  error={fieldState.error?.message}
                  required
                />
              )}
            />

            <TextAreaInput
              id="checkout-plus-custom-css"
              label="Custom CSS"
              details="The styles to apply to the Checkout Plus Widget, if no styles are selected, default styles will be used."
              {...methods.register(
                `widgetConfig.checkoutPlus.elements.${index}.customCss`,
              )}
              error={
                methods.formState.errors?.widgetConfig?.checkoutPlus
                  ?.elements?.[index]?.customCss?.message
              }
            />

            <TextInput
              id="checkout-plus-element-title"
              required
              label={
                selectedKind === CheckoutPlusElementKind.altCheckoutButton ?
                  'Button Title'
                : 'Title'
              }
              {...methods.register(
                `widgetConfig.checkoutPlus.elements.${index}.title`,
              )}
              error={
                methods.formState.errors?.widgetConfig?.checkoutPlus
                  ?.elements?.[index]?.title?.message
              }
            />

            {selectedKind === CheckoutPlusElementKind.checkoutInfo && (
              <>
                <TextInput
                  id="checkout-plus-element-description"
                  label="Description"
                  {...methods.register(
                    `widgetConfig.checkoutPlus.elements.${index}.description`,
                  )}
                  error={
                    methods.formState.errors?.widgetConfig?.checkoutPlus
                      ?.elements?.[index]?.description?.message
                  }
                />

                <Controller
                  name={`widgetConfig.checkoutPlus.elements.${index}.imgUrl`}
                  control={methods.control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <UrlInput
                      id="checkout-plus-image-url"
                      label="Image URL"
                      value={value}
                      onChange={onChange}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </>
            )}

            <div className="mt-2 self-end">
              <Action
                onClick={() => removeElement(index)}
                variant="destructive"
              >
                Remove
              </Action>
            </div>
          </div>
        );
      })}
    </Card>
  );
}

export default function CheckoutPlusSettings() {
  const formRef = useRef<HTMLFormElement>(null);

  const storeId = useStoreId();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [pendingAction, setPendingAction] = useState<
    'checkoutPlusScript' | 'checkoutPlusProducts' | undefined
  >(undefined);

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const methods = useForm<ShipProtectSettingsFormValues>({
    resolver: zodResolver(shipProtectSettingsFormSchema),
    values: data,
  });

  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();

  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit(
        (values) =>
          saveChanges({ shippingProtection: values }).then(() => {
            // reset only after success
            methods.reset();
          }),
        (errors) => {
          console.error(errors);
        },
      )(event)
      .catch(console.error);
  };

  const { mutate: configureSp, isPending } = useConfigureGsp();

  const {
    isCheckoutPlusEnabled,
    isCheckoutPlusScriptConfigured,
    shipProtectUnits,
    storefrontAccessToken,
    accessScopes,
  } = data ?? {};

  const hasMetaobjectAccessScope = accessScopes?.includes(
    'unauthenticated_read_metaobjects',
  );

  const configuredProducts =
    shipProtectUnits?.filter((u) => u.implementationType === 'Product_Variant')
      .length ?? 0;

  const checkoutAction = methods.watch(
    'widgetConfig.checkoutPlus.checkoutAction',
  );

  const factValue = useFactValues();

  const countryCodeOptions = factValue.data?.definite?.['order/country'] ?? [];

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <Page
        title="Checkout Plus"
        secondaryActions={[
          {
            id: 'more-actions',
            content: 'More Actions',
            actions: [
              {
                id: 'configure-checkout-plus-script',
                content: 'Create Script',
                accessibilityLabel: 'Configure Checkout Plus Script',
                onAction: () => {
                  setPendingAction('checkoutPlusScript');
                  configureSp('checkoutPlusScript');
                },
              },
              {
                id: 'configure-checkout-plus-products',
                content: 'Configure Products',
                accessibilityLabel: 'Configure Checkout Plus Products',
                onAction: () => setShowConfirmationModal(true),
              },
            ],
          },
          ...(isCheckoutPlusEnabled ?
            [
              {
                id: 'disable-checkout-plus',
                content: 'Turn Off',
                onAction: () => {
                  methods.setValue('isCheckoutPlusEnabled', false, {
                    shouldDirty: true,
                  });
                  methods.setValue(
                    'widgetConfig.checkoutPlus.isEnabled',
                    false,
                    {
                      shouldDirty: true,
                    },
                  );
                  formRef.current?.requestSubmit();
                },
              },
            ]
          : []),
        ]}
        primaryAction={
          isCheckoutPlusEnabled ? undefined : (
            {
              onAction: () => {
                methods.setValue('isCheckoutPlusEnabled', true, {
                  shouldDirty: true,
                });
                methods.setValue('widgetConfig.checkoutPlus.isEnabled', true, {
                  shouldDirty: true,
                });
                formRef.current?.requestSubmit();
              },
              content: 'Turn On',
            }
          )
        }
      >
        <form
          ref={formRef}
          className="flex flex-col gap-4"
          id={checkoutPlusSettingsFormId}
          onSubmit={submitHandler}
          onReset={(e) => {
            e.preventDefault();
            methods.reset();
          }}
        >
          {!hasMetaobjectAccessScope && (
            <Alert
              title="App Update Required"
              message="Please update the Corso app from within the Shopify App Store, to ensure the Checkout Plus functionality works correctly."
              variant="info"
            />
          )}
          <div className="flex flex-col gap-4">
            <FloatingSave
              isDirty={methods.formState.isDirty}
              isSubmitting={methods.formState.isSubmitting}
              form={checkoutPlusSettingsFormId}
            />
          </div>

          {/* general settings */}
          <Card>
            <div className="flex flex-row justify-between gap-4">
              <Label>Checkout Plus Script</Label>
              {isPending && pendingAction === 'checkoutPlusScript' ?
                <SpinnerIcon className="h-5 w-5 animate-spin" />
              : <Badge
                  variant={
                    isCheckoutPlusScriptConfigured ? 'success' : 'warning'
                  }
                >
                  {isCheckoutPlusScriptConfigured ?
                    'Configured'
                  : 'Not Configured'}
                </Badge>
              }
            </div>

            <div className="flex flex-row justify-between gap-4">
              <Label>Storefront Access Token</Label>
              {isPending && pendingAction === 'checkoutPlusScript' ?
                <SpinnerIcon className="h-5 w-5 animate-spin" />
              : <Badge variant={storefrontAccessToken ? 'success' : 'warning'}>
                  {storefrontAccessToken ? 'Configured' : 'Not Configured'}
                </Badge>
              }
            </div>

            {checkoutAction === 'addVariantToCart' && (
              <div className="flex flex-row justify-between gap-4">
                <Label>Protection Products</Label>
                {isPending && pendingAction === 'checkoutPlusProducts' ?
                  <SpinnerIcon className="h-5 w-5 animate-spin" />
                : <Badge
                    variant={configuredProducts > 0 ? 'success' : 'warning'}
                  >
                    {configuredProducts}
                  </Badge>
                }
              </div>
            )}

            <Controller
              control={methods.control}
              name="widgetConfig.checkoutPlus.displayCountryCodes"
              render={({ field: f, fieldState }) => (
                <MultiSelect
                  togglable
                  label="Display Countries"
                  options={countryCodeOptions}
                  details="The countries where the Checkout Plus widget will be displayed."
                  value={
                    f.value
                      ?.map((v) =>
                        countryCodeOptions
                          .filter((o) => 'value' in o)
                          .find((o) => o.value === v),
                      )
                      .filter(isTruthy) ?? []
                  }
                  onChange={(o) => f.onChange(o.map((v) => v.value))}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Card>

          {/* existing brand owned checkout button setup */}
          <Card>
            <Card.Heading>Checkout Button</Card.Heading>

            <Controller
              control={methods.control}
              name="widgetConfig.checkoutPlus.shouldDisplaySubtotalOnCheckout"
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id="should-display-subtotal-on-checkout"
                  label="Display Subtotal on Checkout"
                  details="When enabled, the subtotal will be displayed on the checkout button."
                  checked={!!value}
                  onChange={onChange}
                  error={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name="widgetConfig.checkoutPlus.checkoutAction"
              control={methods.control}
              render={({ field: { onChange, value }, fieldState }) => (
                <SimpleSelect
                  label="Action"
                  options={checkoutActionOptions}
                  value={value} // be warned, react-hook-form is lying about the value type, as there's no default value
                  onChange={onChange}
                  error={fieldState.error?.message}
                  required
                />
              )}
            />

            <Controller
              control={methods.control}
              name="widgetConfig.checkoutPlus.checkoutSelectors"
              render={({ field: f, fieldState }) => (
                <MultiSelect
                  creatable
                  label="Selectors"
                  options={[]}
                  details="The CSS selectors for the checkout buttons on your store. If no selectors are provided, the default selector will be used."
                  placeholder="Add Selector"
                  value={
                    f.value?.map((v) => ({
                      value: v.toString(),
                      label: v,
                    })) ?? []
                  }
                  onChange={(o) => f.onChange(o.map((v) => v.value))}
                  error={fieldState.error?.message}
                />
              )}
            />

            <Card.Heading>Cart</Card.Heading>

            <Controller
              control={methods.control}
              name="widgetConfig.checkoutPlus.cartSelectors"
              render={({ field: f, fieldState }) => (
                <MultiSelect
                  creatable
                  label="Selectors"
                  options={[]}
                  details="The CSS selectors for the cart on your store. If no selectors are provided, default selectors will be used."
                  placeholder="Add Selector"
                  value={
                    f.value?.map((v) => ({
                      value: v.toString(),
                      label: v,
                    })) ?? []
                  }
                  onChange={(o) => f.onChange(o.map((v) => v.value))}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Card>

          {/* checkout plus elements */}
          <CheckoutPlusElements />

          <Card>
            <div className="flex items-center gap-2">
              <Card.Heading> Widget Preview </Card.Heading>

              <Action
                icon={PencilIcon}
                variant="ghost"
                accessibilityLabel="Edit Widget"
                to={`/${storeId}/settings/shipping-plus/widget`}
              />
            </div>
            <ShippingPlusWidgetPreview
              modal={methods.watch('widgetConfig.modal')}
              description={methods.watch(
                'widgetConfig.shippingPlus.description',
              )}
              label={methods.watch('widgetConfig.shippingPlus.label')}
            />
          </Card>

          <ConfirmModal
            prompt="This will configure the products for use with Checkout Plus, if the products already exist they will be recreated."
            title="Configure Products"
            show={showConfirmationModal}
            confirmText="Confirm"
            variant="caution"
            onConfirm={() => {
              setPendingAction('checkoutPlusProducts');
              configureSp('checkoutPlusProducts');
              setShowConfirmationModal(false);
            }}
            onCancel={() => setShowConfirmationModal(false)}
          />
        </form>
      </Page>
    </FormProvider>
  );
}
